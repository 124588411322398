import { DeliveryType, Product } from "src/redux/apiTypes";
import { selectStoreId } from "src/redux/cart/general/cartSelectors";
import selectAddress from "src/redux/cart/general/selectAddress";
import { useAppSelector } from "src/redux/hooks";
import getUserAgent from "src/utils/api/getUserAgent";
import { useAppConfig } from "src/utils/appConfig/useAppConfig";
import useSWR from "swr";

interface Params {
  count?: number;
  excludedEans?: string[];
}

const maxExcludeEansCount = 150;

const useRecommendations = (params: Params = {}) => {
  const { count = 30, excludedEans = []} = params;
  const { fetchConfig } = useAppConfig();
  const deliveryType = useAppSelector(selectAddress).delivery;
  const storeId = useAppSelector(selectStoreId);
  const userIsLoggedIn = useAppSelector(s => s.user.isLoggedIn);

  //this param should not be in cache dependencies
  const excludedEansParam = excludedEans.length > 0
    ? `&exclude_eans=${excludedEans.slice(0, maxExcludeEansCount).join(',')}`
    : '';

  const { data, error, mutate } = useSWR<Product[]>(
    [storeId, deliveryType, count, userIsLoggedIn, 'recommendations'],
    getRecommendations,
  );

  const recommendationsAreFetching = !data && !error;
  const recommendations = data ? data : [];

  return { recommendations, recommendationsAreFetching, mutateRecommendations: mutate };

  async function getRecommendations(storeId: string, deliveryType: DeliveryType, count: number): Promise<Product[]> {
    const { xChain, xVersion, apiRoot, language } = fetchConfig;

    const response = await fetch(
      `${apiRoot}/stores/${storeId}/products/recommendations/?count=${count}${excludedEansParam}`,
      {
        headers: {
          'Accept-Language': language,
          'x-chain': xChain,
          'x-version': xVersion,
          'X-Delivery-Type': deliveryType,
          'User-Agent': getUserAgent(),
        },
        credentials: 'include',
      },
    );

    return await response.json();
  }
};

export default useRecommendations;
