import { Dispatch } from 'redux';
import { EanAmountMap } from 'src/redux/favorites/types';
import { restrict } from '../../../../utils/cart/restrict';
import { saveCart } from '../../../../utils/cart/saveCart';
import { Product } from '../../../apiTypes';
import { RootState } from '../../../reducers';
import { CartItem } from '../../general/cartTypes';
import { getAvailability } from '../../general/helpers/getAvailability';
import { updateClientCartItems } from '../updateClientCartItems';

const addProductsToClientCart = (
  products: Product[],
  eanAmountMap: EanAmountMap,
) => (dispatch: Dispatch, getState: () => RootState) => {
  const state = getState();
  const deliveryType = state.addressManagement.address.delivery;
  const newClientCartItems = [...state.cart.cart];

  products.forEach((product) => {
    const { ean, currency, price, unit, quantity } = product;
    const cartItemIndex = newClientCartItems.findIndex((i) => i.ean === ean);
    const productInCart = cartItemIndex !== -1;

    if (productInCart) {
      const curCartItem = newClientCartItems[cartItemIndex];
      const limitedAmount = restrict(
        curCartItem.amount + eanAmountMap[ean].amount,
        unit,
        quantity,
      );
      newClientCartItems[cartItemIndex] = {
        ...curCartItem,
        amount: limitedAmount,
      };
    } else {
      const limitedAmount = restrict(
        eanAmountMap[ean].amount,
        unit,
        quantity,
      );
      const isAvailable = getAvailability(product, deliveryType);
      const newCartItem = {
        ean,
        amount: limitedAmount,
        currency,
        price,
        unit,
        product,
        isAvailable,
      } as CartItem;

      newClientCartItems.push(newCartItem);
    }
  });

  dispatch(updateClientCartItems(newClientCartItems));
  saveCart(newClientCartItems);
};

export default addProductsToClientCart;
