import { useRouter } from "next/router";
import { MouseEvent, useMemo } from "react";
import { useDispatch } from "react-redux";
import { Props as ProductRowProps } from "src/components/views/ProductRow/ProductRow";
import useAddProductTo from "src/hooks/useAddProductTo/useAddProductTo";
import { Product } from "src/redux/apiTypes";
import { selectCartItems } from "src/redux/cart/general/cartSelectors";
import { placeProductToCart } from "src/redux/cart/general/operations/placeProductToCart";
import updateAmount from "src/redux/cart/general/operations/updateAmount";
import selectSelectedDelivery from "src/redux/cart/general/selectSelectedDelivery";
import { useAppSelector } from "src/redux/hooks";
import { openModal } from "src/redux/modals";
import openProductModalOrPage from "src/redux/modals/openProductModalOrPage";
import useFavorites from "src/services/favorites/useFavorites";
import { useAppConfig } from "src/utils/appConfig/useAppConfig";
import { getInitialAmount } from "src/utils/cart/getInitialAmount";
import { addToCartButtonsEnum } from "src/utils/marketing/enhancedEcommerce";
import { getBadgeParamsFromProduct } from "src/utils/product/getBadgeParamsFromProduct";
import { getProductTileImage } from "src/utils/product/getProductTileImage";
import mapViewProducts from "src/utils/product/mapViewProducts";
import { modalsManager } from "src/utils/system/modalsManager";
import { getProductsWithAmountPrices } from "../../../utils/cart/products";
import { sendAddRecommendedProductsToCart } from "../helpers/analytics";

const useRecommendationsListProps = (recommendations: Product[]) => {
  const { currency } = useAppConfig();
  const dispatch = useDispatch();

  const {
    getProductIsFavorite,
    toggleFavorite,
  } = useFavorites();

  const isUserAddressSet = !!useAppSelector(selectSelectedDelivery);
  const cartItems = useAppSelector(selectCartItems);
  const viewProducts = mapViewProducts(recommendations, getProductIsFavorite, cartItems);
  const products = useMemo(() => getProductsWithAmountPrices(viewProducts), [viewProducts]);
  const addProductTo = useAddProductTo();
  const router = useRouter();
  const place = addToCartButtonsEnum.RECOMMENDED_CART;

  const handleAddToCart = (product: Product) => {
    const { unit, quantity, ean } = product;
    const amount = getInitialAmount(unit, quantity, ean);

    const addProductsToCart = () => {
      dispatch(placeProductToCart({ ...product, amount }, place));
    };

    if (isUserAddressSet) {
      addProductTo(() => {
        addProductsToCart();

        sendAddRecommendedProductsToCart([product], {
          [product.ean]: {
            amount,
            unit: product.unit,
          },
        });
      }, product);
    } else {
      dispatch(openModal('addressModal', null, {
        onSetNewDelivery: addProductsToCart,
        onPageReload: () => modalsManager.addModal('recommendationsModal'),
      }));
    }
  };

  const handleChangeAmount = (newAmount: number, product: Product) => {
    dispatch(updateAmount(newAmount, place, product));
  };

  const handleFavoriteClick = (product: Product) => {
    toggleFavorite({ product });
  };

  const handleProductClick = (product: Product, e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    dispatch(openProductModalOrPage({
      ean: product.ean,
      slug: product.slug,
      backUrl: router.asPath,
      ctrlKeyIsPressed: e.ctrlKey,
      metaKeyIsPressed: e.metaKey,
    }));
  };

  const productRows: ProductRowProps[] = products.map(product => (
    {
      amount: product.amount,
      place: place,
      title: product.title,
      unit: product.unit,
      weight: product.weight,
      volume: product.volume,
      bundle: product.bundle,
      price: product.price,
      discount: product.discount,
      currency,
      quantity: product.quantity,
      itemInStock: product.in_stock,
      isFavorite: product.isFavorite,
      imageSrc: getProductTileImage(product.img),
      badgeParams: getBadgeParamsFromProduct(product),

      onClick: (e: MouseEvent<HTMLDivElement>) => handleProductClick(product, e),
      onAddToCart: () => handleAddToCart(product),
      onChangeAmount: (amount: number) => handleChangeAmount(amount, product),
      onFavoriteClick: () => handleFavoriteClick(product),
    }
  ));

  return productRows;
};

export default useRecommendationsListProps;
