import { EanAmountMap } from "src/redux/favorites/types";
import { ProductWithAmount } from "src/redux/modals/types";
import { getInitialAmount } from "./getInitialAmount";

export const createEanAmountMap = (items: ProductWithAmount[]): EanAmountMap => {
  return items.reduce((map, { ean, unit, quantity, amount }) => {
    map[ean] = {
      amount: amount || getInitialAmount(unit, quantity, ean),
      unit,
    };
    return map;
  }, {});
};
